<template>
  <a-spin tip="加载中" :spinning="loading">
    <div>
      <a-form-model
        :model="form"
        ref="newsCategoryForm"
        :label-col="labelCol"
        :rules="rules"
        :wrapper-col="wrapperCol"
      >
        <table class="formtable">
          <tr>
            <td style="width: 150px">资产类别</td>
            <td style="text-align: left">
              <a-form-model-item
                label=""
                prop="assetCategory"
                style="margin-bottom: 0px"
              >
                <a-select v-model="form.assetCategory">
                  <a-select-option
                    v-for="item in assetcategory"
                    :value="item.itemValue"
                    :key="item.itemValue"
                    >{{ item.itemName }}</a-select-option
                  >
                </a-select>
              </a-form-model-item>
            </td>
            <td>资产编号</td>
            <td style="text-align: left">
              <a-form-model-item
                label=""
                prop="assetNumber"
                style="margin-bottom: 0px"
              >
                <a-input
                  placeholder="请输入"
                  v-model="form.assetNumber"
                ></a-input
              ></a-form-model-item>
            </td>
          </tr>
          <tr>
            <td>资产名称</td>
            <td style="text-align: left">
              <a-form-model-item
                label=""
                prop="assetName"
                style="margin-bottom: 0px"
              >
                <a-input
                  placeholder="请输入"
                  v-model="form.assetName"
                ></a-input>
              </a-form-model-item>
            </td>
            <td>存放地点</td>
            <td style="text-align: left">
              <a-form-model-item label="" style="margin-bottom: 0px">
                <a-input
                  placeholder="请输入"
                  v-model="form.assetPosition"
                ></a-input>
              </a-form-model-item>
            </td>
          </tr>
          <tr>
            <td>使用部室</td>
            <td style="text-align: left">
              <a-form-model-item label="" prop="org" style="margin-bottom: 0px">
                <a-cascader
                  :options="orgTree"
                  v-model="form.orgId"
                  @change="orgChanged"
                  :fieldNames="{
                    label: 'name',
                    value: 'id',
                    children: 'children',
                  }"
                  placeholder="请选择部室"
                />
              </a-form-model-item>
            </td>

            <td>申领人</td>
            <td colspan="5">
              <a-form-model-item
                label=""
                prop="applyUser"
                style="margin-bottom: 0px"
              >
                <a-input-search
                  v-model="form.applyUserName"
                  @search="onSearch"
                />
              </a-form-model-item>
            </td>
          </tr>
          <tr>
            <td>备注</td>
            <td colspan="3" style="text-align: left">
              <a-form-model-item label="" style="margin-bottom: 0px">
                <a-textarea
                  v-model="form.remark"
                  style="height: 120px; width: 100%"
                />
              </a-form-model-item>
            </td>
          </tr>
          <tr>
            <td>申领时间</td>
            <td style="text-align: left">
              <a-form-model-item
                label=""
                prop="applyTime"
                style="margin-bottom: 0px"
              >
                <a-date-picker v-model="form.applyTime" />
              </a-form-model-item>
            </td>
            <td>上传资产照片</td>
            <td colspan="5" style="text-align: left">
              <a-upload
                :action="uploadFileUrl"
                :fileList="fileList"
                @change="handleFileChange"
              >
                <div>
                  <a-button><a-icon type="upload" />上传文件</a-button>
                </div>
              </a-upload>
            </td>
          </tr>
        </table>

        <a-form-model-item :wrapper-col="{ span: 10, offset: 5 }">
          <a-button
            v-if="form.status == 0 || form.status == 3"
            type="primary"
            @click="handleSubmit(0)"
            style="margin-right: 10px"
            >保存</a-button
          >
          <a-popconfirm
            title="提交后数据不可更改，是否确认？"
            @confirm="handleSubmit(1)"
            v-if="form.status == 0 || form.status == 3"
          >
            <a-button type="danger" style="margin-right: 10px">提交</a-button>
          </a-popconfirm>
          <a-button
            type="primary"
            style="margin-right: 5px"
            v-if="form.status == 1"
            @click="showaudit()"
            >审批</a-button
          >
          <a-button type="default" @click="cancelClick" class="cancelBtn"
            >取消</a-button
          >
        </a-form-model-item>
      </a-form-model>
    </div>
    <a-modal
      title="审批"
      v-model="auditModal.visible"
      width="40%"
      :footer="false"
      destroyOnClose
      :maskClosable="false"
    >
      <audit :info="auditModal.info" @callback="auditCallBack" />
    </a-modal>
    <a-modal
      title="选择用户"
      v-model="selectuservisible"
      width="70%"
      :footer="false"
      destroyOnClose
      :maskClosable="false"
    >
      <selectuser @callback="selectuserCallback" />
    </a-modal>
  </a-spin>
</template>
    <script>
import assetapplyApi from "@/api/asset/apply";
import request from "@/request";
import orgApi from "@/api/org";
import selectuser from "../components/searchuser.vue";
import dictionaryItemApi from "@/api/system/dictionaryItem";
export default {
  name: "assetapply",
  data() {
    return {
      loading: false,
      form: {
        status: 0,
        orgId: [],
      },
      labelCol: { span: 5 },
      wrapperCol: { span: 23 },
      assetcategory: [],
      orgTree: [],
      uploadFileUrl: "",
      fileList: [],
      auditModal: {
        info: null,
        visible: false,
      },
      selectuservisible: false,
      rules: {
        assetCategory: [
          {
            required: true,
            trigger: "blur",
            message: "请选择资产类别",
          },
        ],
        assetNumber: [
          {
            required: true,
            trigger: "blur",
            message: "请选择资产编号",
          },
        ],
        assetName: [
          {
            required: true,
            trigger: "blur",
            message: "请选择资产名称",
          },
        ],
        org: [
          {
            required: true,
            message: "请选择部室",
            trigger: "blur",
            validator: (rule, value, callback) => {
              if (this.form.orgId.length == 0) {
                callback(new Error(""));
                return;
              }
              callback();
            },
          },
        ],
        applyUser: [
          {
            required: true,
            message: "请选择申领人",
            trigger: "blur",
            validator: (rule, value, callback) => {
              if (this.form.applyUser == null || this.form.applyUser == "") {
                callback(new Error(""));
                return;
              }
              callback();
            },
          },
        ],
      },
    };
  },
  props: {
    info: {
      type: Object,
    },
  },
  mounted() {
    this.getDic();
    this.getOrgTree();
    this.uploadFileUrl = request.getUploadUrl("asset");
    if (this.info) {
      this.getData();
    }
  },
  components: {
    selectuser,
  },
  methods: {
    orgChanged(v, selectedOptions) {
      if (selectedOptions == null || selectedOptions.length == 0) {
        this.form.orgName = null;
        this.form.orgId = [];
      } else {
        this.form.orgName = selectedOptions[selectedOptions.length - 1].name;
        this.form.orgId = v;
      }
      this.$forceUpdate();
    },
    onSearch() {
      this.selectuservisible = true;
    },
    selectuserCallback(user) {
      this.form.applyUser = user.userName;
      this.form.applyUserName = user.realName;
      this.selectuservisible = false;
    },
    getOrgTree() {
      orgApi.getTree(true, true).then((res) => {
        if (res.errorCode == this.$commons.RespCode.success) {
          this.orgTree = res.data.tree;
          if (!this.info) {
            this.form.orgId = res.data.path;
            let tree = this.orgTree;
            for (var i = 0; i < res.data.path.length; ++i) {
              var id = res.data.path[i];
              var find = tree.filter((n) => n.id == id);
              if (i == res.data.path.length - 1) {
                this.form.orgName = find[0].name;
              } else {
                tree = find[0].children;
              }
            }
            this.form.applyUserName = res.data.realName;
            this.form.applyUser = localStorage.getItem(
              this.$commons.User.userName
            );
          }
        }
      });
    },
    getDic() {
      dictionaryItemApi.getItemByDictionaryName("资产类别").then((res) => {
        if (res.errorCode == this.$commons.RespCode.success) {
          this.assetcategory = res.data;
        }
      });
    },
    showaudit() {
      this.auditModal.info = this.form;
      this.auditModal.visible = true;
    },
    auditCallBack(data) {
      this.auditModal.visible = false;
      this.$emit("callback", data != null);
    },
    handleFileChange(fileInfo) {
      this.fileList = fileInfo.fileList;
      if (fileInfo.file.status == "error") {
        this.$message.error("上传失败");
      }
    },

    getData() {
      this.loading = true;
      assetapplyApi.getData(this.info.id).then((res) => {
        this.loading = false;
        if (res.errorCode == this.$commons.RespCode.success) {
          res.data.orgId = res.data.orgPath;
          this.form = res.data;
          if (
            this.info &&
            this.info.taskId &&
            this.info.taskId != this.form.taskId
          ) {
            this.form.taskId = this.info.taskId;
          }
          this.form.applyTime = this.$moment(this.form.applyTime);
          if (this.form.pictures) {
            var d = JSON.parse(res.data.pictures);
            var files = [];
            for (var i = 1; i <= d.length; ++i) {
              var a = d[i - 1];
              files.push({
                uid: i,
                name: a.title,
                status: "done",
                response: a,
                url: a.url,
              });
            }

            this.fileList = files;
          }
        }
      });
    },
    handleSubmit(status) {
      this.$refs.newsCategoryForm.validate((valid) => {
        if (valid) {
          let formData = { ...this.form, status };
          formData.orgId = formData.orgId[formData.orgId.length - 1];
          formData.applyTime = formData.applyTime
            ? formData.applyTime.format("YYYY-MM-DD HH:mm:ss")
            : null;
          if (this.fileList.length > 0) {
            var file = [];
            this.fileList.forEach((f) => {
              file.push({
                title: f.response.title,
                url: f.response.url,
              });
            });
            formData.pictures = JSON.stringify(file);
          }
          delete formData.auditRecords;
          this.loading = true;

          if (this.info) {
            formData.id = this.info.id;
          }
          //添加
          assetapplyApi
            .addOrUpdate(formData)
            .then((res) => {
              if (res.errorCode == this.$commons.RespCode.success) {
                this.$message.success(res.errorMsg);
                this.$emit("callback", true);
              } else {
                this.$message.error(res.errorMsg);
              }
              this.loading = false;
            })
            .catch(() => {
              this.loading = false;
            });
        } else {
          return false;
        }
      });
    },
    cancelClick() {
      this.$emit("callback", false);
    },
  },
};
</script>
    